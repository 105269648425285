import React from 'react'
import './home.css'

const Home = (props) => {
    return(
        <div id='home'>
                <h1 id='claudiaborghini'>CLAUDIA BORGHINI</h1>
                <p className='text' >I'm a passionate software engineer with a background in business and marketing.<br></br>I build web applications with Ruby, Rails, Javascript and React</p>

        </div>
    )
}

export default Home