import React from 'react'
import './App.css';
import FirstContainer from './comps/FirstContainer';

function App() {
  return (
    <div>
      <FirstContainer />
    </div>
  );
}

export default App;
